<template>
  <img class="about-us" src="../assets/img/关于我们.jpg" />
  <div class="content-wrap">
    <div class="brand-news">
      <div class="en">COMPANY PROFILE</div>
      <div class="ch">公司简介</div>
    </div>
    <video
      src="https://file.40017.cn/tuojianfilestoreage/2025-01-13/%E8%89%BA%E9%BE%99%E9%85%92%E5%BA%97%E7%A7%91%E6%8A%80%E5%AE%A3%E4%BC%A0%E7%89%87%EF%BC%882024%E4%BF%AE%E6%94%B9%EF%BC%89%20%E5%AE%9A%E7%A8%BF1206_x264-d891fa2cea874d5d9e188f0d83847a5f.mp4"
      :poster="require('@/assets/img/公司优势.jpg')"
      controls
    ></video>
    <p>
      <span class="title">艺龙酒店科技</span>
      是集酒店管理、信息技术和采购贸易为一体的住宿产业综合平台，是代表未来住宿产业创新发展趋势的新型科技平台。艺龙酒店科技于2021年由同程旅行成立。通过内部孵化、战略投资，艺龙酒店科技平台已形成多元化的酒管品牌矩阵，入驻平台的企业有艺程酒管、珀林酒管、启程酒管、美豪酒管、安程酒管、清藤易家酒管、良程酒管、爱电竞酒管、吉程酒管、日本艺丽酒管等多家酒管公司，以及同驿科技、旅智科技等住宿赋能中台。艺龙酒店科技拥有完善的会员体系“艺龙会”、创新体验空间“ROBOT
      AND
      COFFEE”及智慧电视系统“艺龙智屏”，能够充分满足用户商务差旅、休闲度假等多元化出行住宿需求。
    </p>
    <p>
      艺龙酒店科技秉持让旅途更智慧、更温暖的使命，通过技术、酒店营销与运营、供应链、资本等深度赋能住宿行业，助力住宿行业效率和效能不断提升，为全球旅行者提供优质、便捷、安心的住宿服务体验。
    </p>
    <p>
      同程旅行是中国在线旅行行业的创新者和领先者，由同程网络与艺龙旅行网于2018年3月合并而成。2018年11月26日，同程旅行成功在香港联交所主板挂牌上市（股票代码：0780.HK）。同程旅行致力于打造在线旅行一站式平台，业务涵盖交通票务预订（机票、火车票、汽车票、船票等）、住宿预订、景点门票预订，以及跟团游、自由行、邮轮等产品，广泛覆盖了多个出行和度假场景，用户规模超过2亿，是中国两大旅行平台之一。
    </p>
    <p>
      未来，在与同程旅行各业务的深度协同下，艺龙酒店科技将致力于成为卓越的住宿产业综合平台，持续为住宿产业的数字化进程做出贡献，为用户和合作伙伴创造更多新价值。
    </p>
    <img class="mission" src="../assets/img/编组.png" alt="" />
  </div>
  <appFooter />
</template>

<script setup>
import { onMounted } from "vue";
import appFooter from "@/components/footer.vue";
import { trackEvent } from "@/utils/tracklog.js";

onMounted(() => {
  //埋点 关于我们
  trackEvent(
    `关于我们`,
    "浏览",
    `关于我们`,
    {},
    0,
    new Date().getTime()
  );
});
</script>

<style lang="scss" scoped>
.about-us {
  width: 100%;
}
.content-wrap {
  padding: 0 30rem;
  video {
    width: 100%;
  }
  .brand-news {
    position: relative;
    margin: 30rem auto;
    width: fit-content;
    .en {
      font-size: $font-size-large-x;
      color: #eee;
    }
    .ch {
      font-size: $font-size-large-x;
      position: absolute;
      top: 46%;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
    }
  }
  p {
    line-height: 35rem;
    color: $color-text;
    margin-top: 16rem;
    font-size: $font-size-medium-x;
    .title {
      font-size: 35rem;
    }
  }
  .mission {
    width: 100%;
    margin: 30rem 0;
  }
}
</style>

<template>
    <div class="new-wrap">
        <p>{{newsDetail.title}}</p>
        <p>发布时间：{{newsDetail.createTime}}</p>
        <p v-if="newsDetail.content" v-html="replaceSpecialChar(newsDetail.content)"></p>
    </div>
    <div class="footer"></div>
</template>

<script>
import { getNewDetails } from '@/api/index.js';
export default {
	data(){
		return {
			newsDetail: {}
		}
	},
	mounted(){
		getNewDetails(this.$route.query.id).then(res=>{
			this.newsDetail = res;
		})
	},
	methods: {
		replaceSpecialChar(detail) {
			console.log(detail, 'detail')
			return detail.replace(/<img[^>]*>/gi, function(match) {
				return match.replace(/(style="(.*?)")|(width="(.*?)")|(height="(.*?)")/ig,'width=350rpx');
			});
		}
	}
}
</script>

<style>
img{
    max-width: 750rem !important;
}
</style>

<style lang="scss" scoped>
.new-wrap{
    margin-top: 40rem;
    width: 100%;
    //   overflow: hidden;
    p{
        text-align: center;
        line-height: 50rem;
        font-size: $font-size-medium-x;
        &:nth-child(1){
            font-size: $font-size-large-x;
            font-weight: bold;
        }
        &:nth-child(2){
            line-height: 40rem;
            font-size: $font-size-medium-x;
            margin-top: 5px;
            color: $color-text;
        }
         &:nth-child(3){
            padding: 0 20rem;
        }
    }
}
.footer{
    position: relative;
    background: url('../assets/img/footer.jpg') no-repeat;
    background-size: 100% 100%;
    height: 0;
    padding-bottom: 41%;
    color: #fff;
    margin-top: 80px;
}
</style>
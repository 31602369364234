<template>
    <div class="footer"></div>
</template>

<style lang="scss" scoped>
.footer{
    background: url('../assets/img/footer.jpg') no-repeat;
    background-size: 100% 100%;
    height: 0;
    padding-bottom: 41%;
    color: #fff;
}
</style>